import Vue from 'vue'
import VueRouter from 'vue-router'

 //dompurify prevent xss
 import * as DOMPurify from 'dompurify'
 

Vue.use(VueRouter)




const router = new VueRouter({

  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/Home.vue'),
      meta: {
        pageTitle: 'Home',
        breadcrumb: [
          {
            text: 'Home',
            active: true,
          },
        ],
      },
    },
    {
    //juno homeauth is not guarded by guardMyroute since everyone needs to access homepage
    //used by guardMyroute to redirect non-authenticated users to homepage
      path: '/home',
      name: 'home',
      component: () => import('@/views/Home.vue'),
      meta: {
        pageTitle: 'Home',
        breadcrumb: [
          {
            text: 'Home',
            active: true,
          },
        ],
      },
    },
    {
      //juno homeauth is not guarded by guardMyroute since frontend needs to access it to store localstorage data
      path: '/homeauth',
      name: 'homeauth',
      component: () => import('@/views/HomeAuth.vue'),
      meta: {
        pageTitle: 'HomeAuth',
        breadcrumb: [
          {
            text: 'HomeAuth',
            active: true,
          },
        ],
      },
    },
    {
      path: '/help',
      name: 'help-page',
      beforeEnter : guardMyroute,
      component: () => import('@/views/HelpPage.vue'),
      meta: {
        pageTitle: 'Help',
        breadcrumb: [
          {
            text: 'Help',
            active: true,
          },
        ],
      },
    },
    {
      path: '/cloudaiscannerresults',
      name: 'cloudaiscannerresults-page',
      beforeEnter : guardMyroute,
      component: () => import('@/views/CloudAIScannerResults.vue'),
      meta: {
        pageTitle: 'Cloud AI Scanner Results',
        breadcrumb: [
          {
            text: 'Cloud AI Scanner Results',
            active: true,
          },
        ],
      },
    },
    {
      path: '/cloudexposureauditorresults',
      name: 'cloudexposureauditorresults-page',
      beforeEnter : guardMyroute,
      component: () => import('@/views/CloudhostOpenPortFinderResults.vue'),
      meta: {
        pageTitle: 'Cloud Exposure Auditor Results',
        breadcrumb: [
          {
            text: 'Cloud Exposure Auditor Results',
            active: true,
          },
        ],
      },
    },
    {
      path: '/cloudwebservicefinderesults',
      name: 'cloudwebservicefinderesults-page',
      beforeEnter : guardMyroute,
      component: () => import('@/views/CloudWebServiceFinderResults.vue'),
      meta: {
        pageTitle: 'Web Discovery Results',
        breadcrumb: [
          {
            text: 'Web Discovery Results',
            active: true,
          },
        ],
      },
    },
    {
      path: '/assetcaptureresults',
      name: 'assetcaptureresults-page',
      beforeEnter : guardMyroute,
      component: () => import('@/views/AssetHijackResults.vue'),
      meta: {
        pageTitle: 'Asset Capture Results',
        breadcrumb: [
          {
            text: 'Asset Capture Results',
            active: true,
          },
        ],
      },
    },
    {


      path: '/hiddenendpointresults',
      name: 'hiddenendpointresults-page',
      beforeEnter : guardMyroute,
      component: () => import('@/views/HiddenEndpointResults.vue'),
      meta: {
        pageTitle: 'Hidden Endpoint Results',
        breadcrumb: [
          {
            text: 'Hidden Endpoint Results',
            active: true,
          },
        ],
      },
    },
    {


      path: '/sslcertificateresults',
      name: 'sslcertificateresults-page',
      beforeEnter : guardMyroute,
      component: () => import('@/views/SSLCertResults.vue'),
      meta: {
        pageTitle: 'SSL Certificate Results',
        breadcrumb: [
          {
            text: 'SSL Certificate Results',
            active: true,
          },
        ],
      },
    },
    {


      path: '/subdomainresults',
      name: 'subdomainresults-page',
      beforeEnter : guardMyroute,
      component: () => import('@/views/SubdomainResults.vue'),
      meta: {
        pageTitle: 'Subdomain Results',
        breadcrumb: [
          {
            text: 'Subdomain Results',
            active: true,
          },
        ],
      },
    },
    {


      path: '/criticalseverityresults',
      name: 'criticalseverityresults-page',
      beforeEnter : guardMyroute,
      component: () => import('@/views/CriticalSeverityFindingsResults.vue'),
      meta: {
        pageTitle: 'Critical Severity Results',
        breadcrumb: [
          {
            text: 'Critical Severity Results',
            active: true,
          },
        ],
      },
    },

    {


      path: '/highseverityresults',
      name: 'highseverityresults-page',
      beforeEnter : guardMyroute,
      component: () => import('@/views/HighSeverityFindingsResults.vue'),
      meta: {
        pageTitle: 'High Severity Results',
        breadcrumb: [
          {
            text: 'High Severity Results',
            active: true,
          },
        ],
      },
    },
    {


      path: '/mediumseverityresults',
      name: 'mediumseverityresults-page',
      beforeEnter : guardMyroute,
      component: () => import('@/views/MediumSeverityFindingsResults.vue'),
      meta: {
        pageTitle: 'Medium Severity Results',
        breadcrumb: [
          {
            text: 'Medium Severity Results',
            active: true,
          },
        ],
      },
    },
    {


      path: '/lowseverityresults',
      name: 'lowseverityresults-page',
      beforeEnter : guardMyroute,
      component: () => import('@/views/LowSeverityFindingsResults.vue'),
      meta: {
        pageTitle: 'Low Severity Results',
        breadcrumb: [
          {
            text: 'Low Severity Results',
            active: true,
          },
        ],
      },
    },


    {


      path: '/portsresults',
      name: 'portsresults-page',
      beforeEnter : guardMyroute,
      component: () => import('@/views/PortsResults.vue'),
      meta: {
        pageTitle: 'Ports Results',
        breadcrumb: [
          {
            text: 'Ports Results',
            active: true,
          },
        ],
      },
    },
    {


      path: '/vulnerabilityresults',
      name: 'vulnerabilityresults-page',
      beforeEnter : guardMyroute,
      component: () => import('@/views/VulnerabilityResults.vue'),
      meta: {
        pageTitle: 'Vulnerability Results',
        breadcrumb: [
          {
            text: 'Vulnerability Results',
            active: true,
          },
        ],
      },
    },
    {


      path: '/cveresults',
      name: 'cveresults-page',
      beforeEnter : guardMyroute,
      component: () => import('@/views/CVEResults.vue'),
      meta: {
        pageTitle: 'CVE Results',
        breadcrumb: [
          {
            text: 'CVE Results',
            active: true,
          },
        ],
      },
    },
    {


      path: '/exposureresults',
      name: 'exposureresults-page',
      beforeEnter : guardMyroute,
      component: () => import('@/views/ExposureResults.vue'),
      meta: {
        pageTitle: 'Exposure Results',
        breadcrumb: [
          {
            text: 'Exposure Results',
            active: true,
          },
        ],
      },
    },
    {


      path: '/misconfigresults',
      name: 'misconfigresults-page',
      beforeEnter : guardMyroute,
      component: () => import('@/views/MisconfigResults.vue'),
      meta: {
        pageTitle: 'Misconfiguration Results',
        breadcrumb: [
          {
            text: 'Misconfiguration Results',
            active: true,
          },
        ],
      },
    },
    
    {
      path: '/dashboard',
      name: 'dashboardhome-page',
      beforeEnter : guardMyroute,
      component: () => import('@/views/Dashboard.vue'),
      meta: {
        pageTitle: 'SecurityInfinity Dashboard',
        breadcrumb: [
          {
            text: 'SecurityInfinity Dashboard',
            active: true,
          },
        ],
      },
    },
    {
      path: '/severitychart',
      name: 'severitychart-page',
      beforeEnter : guardMyroute,
      component: () => import('@/views/SeverityChart.vue'),
      meta: {
        pageTitle: 'Severity Chart',
        breadcrumb: [
          {
            text: 'Severity Chart',
            active: true,
          },
        ],
      },
    },
    {
      path: '/findingschart',
      name: 'findingschart-page',
      beforeEnter : guardMyroute,
      component: () => import('@/views/FindingsChart.vue'),
      meta: {
        pageTitle: 'Findings Chart',
        breadcrumb: [
          {
            text: 'Findings Chart',
            active: true,
          },
        ],
      },
    },
    
    {
      path: '/riskscore',
      name: 'riskscore-page',
      beforeEnter : guardMyroute,
      component: () => import('@/views/RiskScore.vue'),
      meta: {
        pageTitle: 'Risk Score',
        breadcrumb: [
          {
            text: 'Risk Score',
            active: true,
          },
        ],
      },
    },
    {
      path: '/scanprogresschart',
      name: 'scanprogresschart-page',
      beforeEnter : guardMyroute,
      component: () => import('@/views/ScanProgressChart.vue'),
      meta: {
        pageTitle: 'Scan Progress',
        breadcrumb: [
          {
            text: 'Scan Progress',
            active: true,
          },
        ],
      },
    },
    //{
     // path: '/login',
     // name: 'login',
     // component: () => import('@/views/Login.vue'),
     // meta: {
     //   layout: 'full',
     // },
   // },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})


const userapikey =  localStorage.getItem('userapikey')
const scandomain = localStorage.getItem('scandomain')
const scanid = localStorage.getItem('scanid')

let userapikeysanitized = DOMPurify.sanitize(userapikey);
let scandomainsanitized = DOMPurify.sanitize(scandomain);
let scanidsanitized = DOMPurify.sanitize(scanid);



//this is function to protect page from public access
//only users with valid localstorage values can access the dashboard
function guardMyroute(to, from, next)
{
 var isAuthenticated= false;
// validates localstorage keys to access dashboard 
if(userapikeysanitized && scandomainsanitized && scanidsanitized)
  isAuthenticated = true;
 else
  isAuthenticated= false;
 if(isAuthenticated) 
 {
  next(); // allow to enter route
 } 
 else
 {
  next('/home'); // go to '/login';
 }
}


// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
